<template>
    <div class="about">

        <AppHeading title="Contact Us" />

        <AppOurContact />

    </div>
</template>

<script>
export default {
    components: {
        AppHeading: () => import("@/components/Heading.vue"),
        AppOurContact: () => import("@/components/OurContact.vue"),
    }
}
</script>